var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 grey lighten-5",attrs:{"fluid":""}},[_c('portal',{attrs:{"to":"appBarContentLeft"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.org.name)+" ")])]),_c('v-card',{staticClass:"flex mb-4",attrs:{"flat":"","outlined":"","min-height":"200"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("User Accounts")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","depressed":""},on:{"click":function($event){return _vm.openUserAddDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("add user ")],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.users,"item-key":"displayName","headers":_vm.headers,"loading":_vm.usersLoading,"items-per-page":500,"height":"400","hide-default-footer":"","dense":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])]}},{key:"item.qhp",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-checkbox',{staticClass:"pa-0 ml-2 mt-0 mb-0",attrs:{"hide-details":"","color":"primary"},on:{"change":function($event){return _vm.userRolesOnChange(item)}},model:{value:(item.roles.qhp),callback:function ($$v) {_vm.$set(item.roles, "qhp", $$v)},expression:"item.roles.qhp"}})],1)]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-checkbox',{staticClass:"pa-0 ml-2 mt-0 mb-0",attrs:{"hide-details":"","color":"primary"},on:{"change":function($event){return _vm.userRolesOnChange(item)}},model:{value:(item.roles.admin),callback:function ($$v) {_vm.$set(item.roles, "admin", $$v)},expression:"item.roles.admin"}})],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.openUserRemoveDialog(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}])})],1),_c('v-layout',{staticClass:"patient-page",attrs:{"justify-start":""}},[_c('org-user-add-dialog',{ref:"userAddDialog",on:{"submit":_vm.userAddDialogSubmit}}),_c('org-user-remove-dialog',{ref:"userRemoveDialog",on:{"submit":_vm.userRemoveDialogSubmit}}),_c('v-flex',{attrs:{"xs12":""}},[_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.invites.length > 0),expression:"invites.length > 0"}],staticClass:"flex mb-4",attrs:{"flat":"","color":"grey lighten-4","min-height":"200"}},[_c('v-card',{staticClass:"pa-4 pb-1",attrs:{"flat":"","color":"transparent"}},[_c('v-row',{staticClass:"mb-2",attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('span',{staticClass:"font-weight-bold text-h6"},[_vm._v("Pending Invites")])])],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0 grey lighten-4",staticStyle:{"max-width":"100%"},attrs:{"items":_vm.invites,"item-key":"id","headers":_vm.inviteHeaders,"hide-default-header":_vm.isMobile,"hide-default-footer":"","loading":_vm.invitesLoading},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(invite,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(invite.email))]),_c('td',[_vm._v(_vm._s(invite.expires))]),_c('td',{staticStyle:{"max-width":"80px","width":"80px"}},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.deleteInviteWithToast(invite.id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)])}),0)]}}])})],1)],1)],1)],1),_c('v-card',{staticClass:"flex mb-4",attrs:{"flat":"","outlined":"","min-height":"200"}},[_c('org-provider-add-dialog',{ref:"providerAddDialog",on:{"submit":_vm.providerAddSubmit}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Providers")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","depressed":""},on:{"click":function($event){return _vm.openProviderAddDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("add provider ")],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.providerArray,"headers":_vm.providerHeaders,"items-per-page":500,"height":"300","hide-default-footer":"","dense":"","item-class":_vm.itemClass,"fixed-header":""},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{staticStyle:{"margin-left":"-11px","font-size":"14px"},attrs:{"readonly":_vm.editingProvider !== null && _vm.editingProvider !== item.id,"background-color":"transparent","hide-details":"","flat":"","dense":"","solo":""},on:{"keyup":function($event){_vm.editingProvider = item.id}},model:{value:(item.displayName),callback:function ($$v) {_vm.$set(item, "displayName", $$v)},expression:"item.displayName"}})],1)]}},{key:"item.credential",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{staticStyle:{"margin-left":"-11px","font-size":"14px"},attrs:{"background-color":"transparent","readonly":_vm.editingProvider !== null && _vm.editingProvider !== item.id,"hide-details":"","flat":"","dense":"","solo":""},on:{"keyup":function($event){_vm.editingProvider = item.id}},model:{value:(item.credential),callback:function ($$v) {_vm.$set(item, "credential", $$v)},expression:"item.credential"}})],1)]}},{key:"item.npi",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{staticStyle:{"margin-left":"-11px","font-size":"14px"},attrs:{"background-color":"transparent","readonly":_vm.editingProvider !== null && _vm.editingProvider !== item.id,"hide-details":"","flat":"","dense":"","solo":""},on:{"keyup":function($event){_vm.editingProvider = item.id}},model:{value:(item.npi),callback:function ($$v) {_vm.$set(item, "npi", $$v)},expression:"item.npi"}})],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","no-gutters":"","justify":"end"}},[_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[(_vm.editingProvider === item.id)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","dark":"","color":"green","x-small":""},on:{"click":function($event){_vm.updateProviders(_vm.providerArray), (_vm.editingProvider = null)}}},[_vm._v(" save ")]):_vm._e()],1),_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[(_vm.editingProvider === item.id)?_c('v-btn',{attrs:{"x-small":"","depressed":"","dark":"","color":"red"},on:{"click":_vm.resetProviderArray}},[_vm._v(" cancel ")]):_vm._e()],1),_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.removeProvider(item.id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}])})],1),_c('org-settings',{staticClass:"mb-4",attrs:{"org":_vm.org.id}}),_c('org-settings-reminder-texts',{staticClass:"mb-4"}),_c('alert-rule-settings',{staticClass:"mb-4",attrs:{"orgMode":true}}),_c('org-note-templates',{staticClass:"mb-4"}),_c('org-text-templates')],1)}
var staticRenderFns = []

export { render, staticRenderFns }