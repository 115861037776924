<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" 
  :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'scale-transition'"
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar flat color="toolbars">
        <v-toolbar-title>Add User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5 pt-2" >
        <v-form ref="emailForm" v-model="valid">
          <v-text-field
          outlined flat
          v-model="emailAddress"
          required
          label="Email Address"
          :rules="emailRules"
          ></v-text-field>
          <v-subheader>PERMISSIONS</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Manage Users</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
              color="primary"
              v-model="admin"
              hide-details
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Track Time</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
              color="primary"
              v-model="qhp"
              hide-details
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-form>
        <v-layout row class="mx-1 mt-2">
          <span style="text-align: justify">
            New users will receive an invitation email with instructions to create an account. 
            For security, the invitation email will expire within 72 hours if unused. Existing users will be added immediately.
          </span>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-btn depressed  @click="close">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" dark @click="submit" :disabled="!emailValid">Send Invite</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [DialogMixin, HelperMixin],
  data() {
    return {
      emailAddress: '',
      valid: false,
      admin: false,
      qhp: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v =>
          this.validateEmail(v) ||
          'E-mail must be valid'
      ],
    }
  },
  computed: {
    emailValid() {
      return this.validateEmail(this.emailAddress)
    }
  },
  mounted() {
    this.$on('open', this.onOpen)
    this.$on('close', this.onClose)
  },
  methods: {
    submit() {
      this.$emit('submit', { email: this.emailAddress, roles: {admin: this.admin, qhp99457: this.qhp} })
      this.close()
    },
    onClose() {
      this.$refs.emailForm.reset()
      this.$refs.emailForm.resetValidation()
    },
    close() {
      this.emailAddress = ''
      this.cancel()
    },
  }
}
</script>