<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" 
  :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'scale-transition'"
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar flat dark color="red">
        <v-toolbar-title>Remove User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-3">
        <span v-if="options.user">{{options.user.email}} will be removed from the organization. 
        They will immediately lose the ability to view associated with this organization. 
        The user account will not be deleted. Are you sure you want to remove the user?</span>
      </v-card-text>

      <v-card-actions>
        <v-btn depressed  @click="close">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed color="red" dark @click="submit">Remove User</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'

export default {
  mixins: [DialogMixin],
  mounted() {
    this.$on('open', this.onOpen)
  },
  methods: {
    submit() {
      this.$emit('submit', this.options.user.id)
      this.close()
    },
    close() {
      this.cancel()
    },
  }
}
</script>