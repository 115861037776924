<template>
  <v-container class="pa-4 grey lighten-5" fluid>
    <portal to="appBarContentLeft">
      <span style="font-weight: bold"> {{ org.name }} </span>
    </portal>

    <v-card flat class="flex mb-4" outlined min-height="200">
      <v-toolbar flat>
        <span class="font-weight-bold">User Accounts</span>
        <v-spacer />
        <v-btn color="primary" text depressed @click="openUserAddDialog()">
          <v-icon left>mdi-plus</v-icon>add user
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>
      <v-data-table
        :items="users"
        item-key="displayName"
        class="elevation-0"
        :headers="headers"
        :loading="usersLoading"
        :items-per-page="500"
        height="400"
        hide-default-footer
        dense
        fixed-header
      >
        <template v-slot:item.displayName="{ item }">
          <v-row no-gutters> {{ item.firstName }} {{ item.lastName }} </v-row>
        </template>

        <template v-slot:item.qhp="{ item }">
          <v-row no-gutters justify="center">
            <v-checkbox
              hide-details
              class="pa-0 ml-2 mt-0 mb-0"
              color="primary"
              @change="userRolesOnChange(item)"
              v-model="item.roles.qhp"
            ></v-checkbox>
          </v-row>
        </template>

        <template v-slot:item.admin="{ item }">
          <v-row no-gutters justify="center">
            <v-checkbox
              hide-details
              class="pa-0 ml-2 mt-0 mb-0"
              color="primary"
              @change="userRolesOnChange(item)"
              v-model="item.roles.admin"
            ></v-checkbox>
          </v-row>
        </template>

        <template v-slot:item.remove="{ item }">
          <v-row no-gutters justify="center">
            <v-btn color="red" @click="openUserRemoveDialog(item)" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </template>

        <!-- <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(user, index) in items" :key="index">
              <td>
                <v-list-item dense class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td>
                <v-layout row justify-center align-center>
                  <v-checkbox
                    hide-details
                    class="pa-0 ml-2 mt-0 mb-0"
                    color="primary"
                    @change="userRolesOnChange(user)"
                    v-model="user.roles.admin"
                  ></v-checkbox>
                </v-layout>
              </td>
              <td style="max-width: 120px; width: 120px">
                <v-layout row class="mx-0" justify-center>
                  <v-checkbox
                    hide-details
                    class="pa-0 ml-2 mt-0 mb-0"
                    color="primary"
                    @change="userRolesOnChange(user)"
                    v-model="user.roles.qhp"
                  ></v-checkbox>
                </v-layout>
              </td>
              <td style="max-width: 80px; width: 80px">
                <v-layout row justify-center>
                  <v-btn color="red" @click="openUserRemoveDialog(user)" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </tr>
          </tbody>
        </template> -->
      </v-data-table>
    </v-card>

    <v-layout class="patient-page" justify-start>
      <org-user-add-dialog @submit="userAddDialogSubmit" ref="userAddDialog" />
      <org-user-remove-dialog @submit="userRemoveDialogSubmit" ref="userRemoveDialog" />
      <v-flex xs12>
        <v-expand-transition>
          <v-card flat class="flex mb-4" color="grey lighten-4" min-height="200" v-show="invites.length > 0">
            <v-card flat color="transparent" class="pa-4 pb-1">
              <v-row align="center" justify="space-between" class="mb-2" no-gutters>
                <span class="font-weight-bold text-h6">Pending Invites</span>
              </v-row>
            </v-card>

            <v-divider></v-divider>
            <v-data-table
              class="elevation-0 grey lighten-4"
              style="max-width: 100%"
              :items="invites"
              item-key="id"
              :headers="inviteHeaders"
              :hide-default-header="isMobile"
              hide-default-footer
              :loading="invitesLoading"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(invite, index) in items" :key="index">
                    <td>{{ invite.email }}</td>
                    <td>{{ invite.expires }}</td>
                    <td style="max-width: 80px; width: 80px">
                      <v-layout row justify-center>
                        <v-btn color="red" @click="deleteInviteWithToast(invite.id)" icon>
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-expand-transition>
      </v-flex>
    </v-layout>

    <v-card flat class="flex mb-4" outlined min-height="200">
      <org-provider-add-dialog @submit="providerAddSubmit" ref="providerAddDialog" />
      <v-toolbar flat>
        <span class="font-weight-bold">Providers</span>
        <v-spacer />
        <v-btn color="primary" text depressed @click="openProviderAddDialog()">
          <v-icon left>mdi-plus</v-icon>add provider
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>
      <v-data-table
        :items="providerArray"
        class="elevation-0"
        :headers="providerHeaders"
        :items-per-page="500"
        height="300"
        hide-default-footer
        dense
        :item-class="itemClass"
        fixed-header
      >
        <template v-slot:item.lastName="{ item }">
          <v-row no-gutters>
            <v-text-field
              style="margin-left: -11px; font-size: 14px"
              :readonly="editingProvider !== null && editingProvider !== item.id"
              @keyup="editingProvider = item.id"
              background-color="transparent"
              hide-details
              flat
              dense
              solo
              v-model="item.displayName"
            >
            </v-text-field>
          </v-row>
        </template>

        <template v-slot:item.credential="{ item }">
          <v-row no-gutters>
            <v-text-field
              style="margin-left: -11px; font-size: 14px"
              background-color="transparent"
              :readonly="editingProvider !== null && editingProvider !== item.id"
              @keyup="editingProvider = item.id"
              hide-details
              flat
              dense
              solo
              v-model="item.credential"
            >
            </v-text-field>
          </v-row>
        </template>

        <template v-slot:item.npi="{ item }">
          <v-row no-gutters>
            <v-text-field
              style="margin-left: -11px; font-size: 14px"
              background-color="transparent"
              :readonly="editingProvider !== null && editingProvider !== item.id"
              @keyup="editingProvider = item.id"
              hide-details
              flat
              dense
              solo
              v-model="item.npi"
            >
            </v-text-field>
          </v-row>
        </template>

        <template v-slot:item.remove="{ item }">
          <v-row align="center" no-gutters justify="end">
            <v-slide-x-reverse-transition hide-on-leave>
              <v-btn
                v-if="editingProvider === item.id"
                depressed
                dark
                color="green"
                class="mr-2"
                x-small
                @click="updateProviders(providerArray), (editingProvider = null)"
              >
                save
              </v-btn>
            </v-slide-x-reverse-transition>

            <v-slide-x-reverse-transition hide-on-leave>
              <v-btn x-small v-if="editingProvider === item.id" depressed dark color="red" @click="resetProviderArray">
                cancel
              </v-btn>
            </v-slide-x-reverse-transition>

            <v-btn color="red" @click="removeProvider(item.id)" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <org-settings :org="org.id" class="mb-4" />
    <org-settings-reminder-texts class="mb-4" />
    <alert-rule-settings :orgMode="true" class="mb-4" />
    <org-note-templates class="mb-4" />
    <org-text-templates />
  </v-container>
</template>
<style>
.headerStyle {
  background-color: green;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import OrgUserAddDialog from '@/components/dialogs/OrgUserAdd'
import OrgUserRemoveDialog from '@/components/dialogs/OrgUserRemove'
import bus from '@/core/helpers/bus'
import OrgNoteTemplates from '../components/OrgNoteTemplates.vue'
import OrgTextTemplates from '../components/OrgTextTemplates.vue'
import OrgSettings from '@/components/OrgSettings'
import OrgSettingsReminderTexts from '../components/OrgSettingsReminderTexts.vue'
import AlertRuleSettings from '@/components/AlertRuleSettings'
import OrgProviderAddDialog from '@/components/dialogs/OrgProviderAddDialog'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      resetLoading: false,
      email: null,
      emailRules: [v => !!v || 'E-mail is required'],
      headers,
      inviteList: [],
      inviteHeaders,
      providerHeaders,
      editingProvider: null,
      editsToProvider: null,
      providerTableKey: 0,
      providerArray: [],
    }
  },
  components: {
    OrgSettings,
    AlertRuleSettings,
    OrgNoteTemplates,
    OrgTextTemplates,
    OrgSettingsReminderTexts,
    OrgUserAddDialog,
    OrgUserRemoveDialog,
    OrgProviderAddDialog,
  },
  computed: {
    ...mapGetters('auth', ['isOrgAdmin']),
    ...mapState('org', ['org', 'invites', 'invitesLoading', 'users', 'usersLoading', 'providers']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
  },
  watch: {
    providers: {
      handler: function () {
        this.resetProviderArray()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    ...mapActions('org', [
      'deleteInvite',
      'inviteUser',
      'removeUser',
      'updateUserRoles',
      'addProvider',
      'updateProviders',
    ]),
    resetProviderArray() {
      this.editingProvider = null
      let prov = []
      Object.keys(this.providers).forEach(npi => {
        let provider = JSON.parse(JSON.stringify(this.providers[npi]))
        prov.push(provider)
      })
      this.providerArray = prov.sort((a, b) => a.lastName.localeCompare(b.lastName))
    },
    itemClass(item) {
      return this.editingProvider === item.id ? 'grey lighten-2' : 'white'
    },
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    deleteInviteWithToast(inviteId) {
      this.deleteInvite(inviteId)
        .then(() => {
          this.toast('success', 'Invite Removed')
        })
        .catch(error => {
          console.error(error)
          this.toast('error', 'Error Removing Invite')
        })
    },
    openUserAddDialog() {
      this.$refs.userAddDialog.open()
    },
    openProviderAddDialog() {
      this.$refs.providerAddDialog.open()
    },
    openUserRemoveDialog(user) {
      this.$refs.userRemoveDialog.open({ user })
    },
    sendReset() {
      if (this.$refs.pwreset.validate()) {
        this.resetPassword(this.email)
          .then(() => {
            bus.$emit('toast', { type: 'success', text: 'Email Sent' })
          })
          .catch(error => {
            console.log(error)
            bus.$emit('toast', { type: 'error', text: 'Error Sending Email' })
          })
      }
    },
    removeProvider(id) {
      let newProviderArray = this.arrayOfProviders.filter(e => e.id !== id)
      this.updateProviders(newProviderArray)
    },
    cancelProviderUpdate() {
      this.editingProvider = null
      this.providerTableKey + this.providerTableKey + 1
    },
    providerAddSubmit(provider) {
      //add the provider to the list
      this.addProvider(provider)
    },
    userAddDialogSubmit(email, roles) {
      this.inviteUser(email, roles)
        .then(autoAdded => {
          if (autoAdded) {
            this.toast('success', 'User Added to Organization')
          } else {
            this.toast('success', 'Invitation Email Sent')
          }
        })
        .catch(error => {
          console.error(error)
          this.toast('error', 'Error Inviting User: ' + error)
        })
    },
    userRemoveDialogSubmit(userId) {
      this.removeUser(userId)
        .then(() => {
          this.toast('success', 'User Removed')
        })
        .catch(error => {
          console.error(error)
          this.toast('error', 'Error Removing User')
        })
    },
    userRolesOnChange(user) {
      this.updateUserRoles(user)
        .then(() => {
          this.toast('success', 'Permissions Updated')
        })
        .catch(error => {
          console.log(error)
          this.toast('error', 'Please Try Again Later')
        })
    },
  },
  beforeMount() {
    if (!this.isOrgAdmin) {
      this.$router.push({ name: 'Inbox' })
    }
  },
}

const inviteHeaders = [
  {
    text: 'Recipient',
    align: 'start',
    sortable: false,
    value: 'email',
  },
  {
    text: 'Expiration',
    align: 'start',
    sortable: false,
    value: 'expires',
  },
  {
    text: 'Delete',
    align: 'center',
    sortable: false,
  },
]
const headers = [
  {
    text: 'User',
    align: 'start',
    sortable: false,
    value: 'displayName',
  },
  {
    text: 'Email',
    align: 'start',
    sortable: false,
    value: 'email',
  },
  {
    text: 'Admin',
    align: 'center',
    sortable: false,
    value: 'admin',
    width: 80,
  },
  {
    text: 'QHP',
    align: 'center',
    sortable: false,
    value: 'qhp',
    width: 80,
  },
  {
    text: 'Remove',
    align: 'center',
    sortable: false,
    value: 'remove',
    width: 80,
  },
]
const providerHeaders = [
  {
    text: '    Display Name',
    align: 'start',
    sortable: true,
    value: 'lastName',
  },
  {
    text: 'Credentials',
    align: 'start',
    sortable: true,
    value: 'credential',
  },
  {
    text: 'NPI',
    align: 'start',
    sortable: false,
    value: 'npi',
  },
  {
    text: '',
    align: 'right',
    sortable: false,
    value: 'remove',
    width: 300,
  },
]
</script>
