<template>
  <v-dialog
    v-model="show"
    :max-width="options.maxWidth"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'scale-transition'"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card rounded="lg" flat>
      <v-toolbar dense dark flat class="font-weight-bold" style="font-size: 20px">
        New Provider
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="px-4 py-4" flat tile>
        <v-form ref="providerForm">
          <v-combobox v-model="npi" solo flat background-color="grey lighten-3" label="NPI" />
          <v-row no-gutters>
            <v-col class="pr-4">
              <v-text-field
                v-model="firstName"
                required
                solo
                flat
                background-color="grey lighten-3"
                label="First Name"
                :rules="[v => !!v || 'Name Required']"
                @keyup="refactorDisplayName"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="lastName"
                required
                solo
                flat
                background-color="grey lighten-3"
                label="Last Name"
                :rules="[v => !!v || 'Name Required']"
                @keyup="refactorDisplayName"
              />
            </v-col>
          </v-row>
          <v-text-field
            required
            style="max-width: 200px"
            solo
            flat
            background-color="grey lighten-3"
            label="Credentials"
            v-model="credential"
            :rules="[v => !!v || 'Credentials Required']"
            @keyup="refactorDisplayName"
          />
          <v-text-field
            required
            outlined
            flat
            label="Name as it will appear in lists"
            v-model="displayName"
            :rules="[v => !!v || 'Required']"
          />
          <v-row class="pt-4" justify="end" no-gutters>
            <v-btn @click="submit()" depressed>Add</v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [DialogMixin, HelperMixin],
  data() {
    return {
      npi: null,
      firstName: null,
      lastName: null,
      credential: null,
      displayName: null,
    }
  },
  computed: {},
  mounted() {
    this.$on('open', this.onOpen)
    this.$on('close', this.onClose)
  },
  methods: {
    refactorDisplayName() {
      let name = ''
      if (this.firstName) {
        name = this.firstName
      }

      if (this.lastName) {
        name = name + ' ' + this.lastName
      }

      if (this.credential) {
        name = name + ' ' + this.credential
      }

      this.displayName = name
    },
    submit() {
      if (this.$refs.providerForm.validate()) {
        this.$emit('submit', {
          npi: this.npi,
          firstName: this.firstName,
          lastName: this.lastName,
          credential: this.credential,
          displayName: this.displayName,
        })
        this.close()
      }
    },
    onClose() {
      this.$refs.providerForm.reset()
      this.$refs.providerForm.resetValidation()
    },
    close() {
      this.npi = null
      this.firstName = null
      this.lastName = null
      this.credential = null
      this.displayName = null
      this.cancel()
    },
  },
}
</script>
